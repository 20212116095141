import React from 'react';

import Typography from '@material-ui/core/Typography';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import { useTranslation } from 'react-i18next';

import Keyboard from 'react-simple-keyboard';
import './EditDialog.css';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  keyboard: {
    color: 'black',
  },
}));


export default function FormDialog(props) {
  const classes = useStyles();

  const { t } = useTranslation();
  const [name, setName] = React.useState(props.name);

  const [layoutName, setLayoutName] =  React.useState('default');
  const [isLock, setIsLock] =  React.useState(false);

  const keyboardRef = React.useRef(null);

  const handleSave = () => {
    if (props.doc) {
      props.handleSave(props.doc, name);
    } else {
      props.handleSave(name);
    }
  };

  const handleDelete = () => {
    if (props.doc) {
      props.handleDelete(props.doc);
    } else {
      props.handleDelete();
    }
  };

  const handleClose = () => {
    props.handleClose();
  };

  const handleChange = (event) => {
    setName(event.target.value);
  }

  const handleKeyPress = (button) => {
    switch(button) {
      case '{bksp}':
        setName((name) => {return name.slice(0,-1)});
        break;
      case '{shift}':
        handleShift();
        setIsLock(false);
        break;
      case '{lock}':
        setIsLock((isLock) => !isLock);
        handleShift();
        break;

      case '{space}':
       setName((name) => {return name + ' '});
        break;
      default:
        setName((name) => {return name + button});
        if (!isLock) {
          setLayoutName('default');
        }
        break;
    }
  }

  const handleShift = () => {
    setLayoutName(layoutName === "default" ? "shift" : "default")
  };


  return (
      <Dialog open={true} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth={false}>
        <DialogContent style={{minWidth: '960px'}} >
          <TextField
            autoFocus
            value={name}
            onChange={handleChange}
            margin="dense"
            id="name"
            label={props.labelText ? props.labelText : t('Name')}
            fullWidth
            onKeyPress={(ev) => {
                if (ev.key === 'Enter') { // use enter to save
                  handleSave();
                  ev.preventDefault();
                }
              }}
          />
          { props.hint &&
            <Typography color="secondary">{props.hint}</Typography>
          }
          { props.showKeyboard &&
          <Keyboard
            onKeyPress={handleKeyPress}
             layoutName={layoutName}
             layout={{
               default: [
                 "` 1 2 3 4 5 6 7 8 9 0 - = {bksp}",
                 "q w e r t y u i o p [ ] \\",
                 "{lock} a s d f g h j k l ; '",
                 "{shift} z x c v b n m , . / {shift}",
                 "{space}"
               ],
               shift: [
                 "~ ! @ # $ % ^ & * ( ) _ + {bksp}",
                 "Q W E R T Y U I O P { } |",
                 '{lock} A S D F G H J K L : "',
                 "{shift} Z X C V B N M < > ? {shift}",
                 "{space}"
               ]
             }}
             display={{
               '{bksp}': 'delete',
               '{enter}': 'enter',
               '{shift}': 'shift',
               '{s}': 'shift',
               '{tab}': 'tab',
               '{lock}': 'caps',
               '{accept}': 'Submit',
               '{space}': ' ',
               '{//}': ' '
              }}
          />
        }
        </DialogContent>
        <DialogActions>
          {props.handleDelete &&
            <Button onClick={handleDelete} color="secondary">
              {props.deleteText ? props.deleteText : t('Delete')}
            </Button>
          }
          {props.handleClose &&
          <Button onClick={handleClose} color="primary">
            {props.cancelText ? props.cancelText : t('Cancel')}
          </Button>
          }
          {props.handleSave &&
            <Button onClick={handleSave} color="primary">
              {props.saveText ? props.saveText : t('Save')}
            </Button>
          }
        </DialogActions>

      </Dialog>

  );
}
