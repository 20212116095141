import React, {useEffect, useState, useContext} from 'react';

import _ from 'lodash';
import { Router, Route } from "react-router-dom";

import firebase from 'firebase/app';
import 'firebase/firestore';
import { useCollection } from 'react-firebase-hooks/firestore';

import * as firebaseui from 'firebaseui';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';

import { useTranslation } from 'react-i18next';


import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import CssBaseline from '@material-ui/core/CssBaseline';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';

import Drawer from './components/Drawer';
import Settings from './components/Settings';
import Inspection from './components/Inspection';
import History from './components/History';


import {Provider, Context} from './Context';

import browserhistory from './Browserhistory';


const io = require('socket.io-client');
const socket = io('http://localhost:3020');



const firebaseConfig = {
  apiKey: process.env.REACT_APP_apiKey,
  authDomain: process.env.REACT_APP_authDomain,
  databaseURL: process.env.REACT_APP_databaseURL,
  projectId: process.env.REACT_APP_projectId,
  storageBucket: process.env.REACT_APP_storageBucket,
  messagingSenderId: process.env.REACT_APP_messagingSenderId,
  appId: process.env.REACT_APP_appId,
  measurementId: process.env.REACT_APP_measurementId,
};

console.log("SELECTED ENVIRONMENT: " + process.env.REACT_APP_SELECTED_ENV);
console.log(firebaseConfig);
// Initialize Firebase
firebase.initializeApp(firebaseConfig)

// The default cache size threshold is 40 MB. Configure "cacheSizeBytes"
// for a different threshold (minimum 1 MB) or set to "CACHE_SIZE_UNLIMITED"
// to disable clean-up.
firebase.firestore().settings({
  cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED
});
firebase.firestore().enablePersistence({synchronizeTabs:true})
  .catch(function(err) {
      console.error(err);
      if (err.code === 'failed-precondition') {
          // Multiple tabs open, persistence can only be enabled
          // in one tab at a a time.
          // ...
      } else if (err.code === 'unimplemented') {
          // The current browser does not support all of the
          // features required to enable persistence
          // ...
      }
  });

// firebase.auth().languageCode = 'de';
firebase.auth().useDeviceLanguage();

// Configure FirebaseUI.
const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'redirect',
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  // signInSuccessUrl: '/signedIn',
  callbacks: {
    // Avoid redirects after sign-in.
    signInSuccessWithAuthResult: () => false
  },

  // Required to enable one-tap sign-up credential helper.
  credentialHelper: firebaseui.auth.CredentialHelper.NONE,
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    // firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    // firebase.auth.PhoneAuthProvider.PROVIDER_ID,
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
  ]
};


const theme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#FFC50B',
      // light: 'red',
      dark: '#FFB300',
      contrastText: '#000',
    },
    secondary: {
      main: '#EF0000',
      // light: 'red',
      dark: '#C62828',
      contrastText: '#000',
    },

  },
});



const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  updateSnack: {
    backgroundColor: '#FFC50B',
  },
}));

// block the app before the device settings are loadeds
function DeviceSettingsLoader(props) {
  const context = useContext(Context);
  const { t } = useTranslation();

  if(context.deviceSettings && context.deviceSettings.data()) {
    return props.children
  } else {
    return <Typography>{t('Loading Device settings...')}</Typography>;
  }
}

function App(props) {
  const classes = useStyles();
  const { t } = useTranslation();


  const [isSignedIn, setIsSignedIn] = useState(false);
  const [isUpdateAvailable, setIsUpdateAvailable] = useState(false);

  let uid = "denied";
  if (firebase.auth().currentUser) {
    uid = firebase.auth().currentUser.uid
    // console.log('user logged in... uid: ' + uid);
  }

  const onUpdate = (registration) => {
    console.log('APP ON UPDATE');
    setIsUpdateAvailable(registration);
  }
  console.log('App updater...');
  props.updater(onUpdate);

  useEffect(() => {
    return firebase.auth().onAuthStateChanged(
          (user) => setIsSignedIn(!!user)
      );
  })

  // we use the effect to disable the right click on the page. on the touchscreen a long touch is doing a
  useEffect(() => {
    function contextmenu (event) {
      event.preventDefault()
    }
    window.addEventListener("contextmenu", contextmenu);
    return () => window.removeEventListener("recontextmenusize", contextmenu);
  }, []);

  const [accessValue, accessLoading, accessError] = useCollection(
    firebase.firestore().collectionGroup('customerusers').where('uid', '==', uid)
  );
  if (accessError) console.error(accessError);

  if (!isSignedIn) {
    return (
      <div>
        <StyledFirebaseAuth
          uiConfig={uiConfig}
          firebaseAuth={firebase.auth()}/>
      </div>
    );
  }
  else {
    if (accessLoading || !accessValue || _.size(accessValue.docs) < 1) {
      return <Typography>{t('Loading User...')}</Typography>;
    } else {
      let customerIdList = _.map(accessValue.docs, (e) => {
        return e.ref.parent.parent.id;
      })
      let customerId = customerIdList[0];
      let deviceId = accessValue.docs[0].data().deviceId;
      console.log("DEVICEID: " + deviceId);
      // console.log("CustomerId: " + customerId);
      // console.log("customerIdList: ");
      // console.log(customerIdList);

      return (
        <div className={classes.root}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Router history={browserhistory}>
              <Provider isUpdateAvailable={isUpdateAvailable} checkUpdate={props.checkUpdate} initialSocket={socket} initialSelectedCustomerId={customerId} initialDeviceId={deviceId} initialCustomerIdList={customerIdList}>
                <DeviceSettingsLoader >
                  <Drawer />
                  <main className={clsx(classes.content)}>
                    <Route path="/(|inspection)" render={(props) => <Inspection />} />
                    <Route path="/history" render={(props) => <History />} />
                    <Route path="/settings" render={(props) => <Settings />} />
                  </main>
                  {
                    isUpdateAvailable &&
                    <Snackbar
                            className={classes.updateSnack}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'right',
                            }}
                            open={true}
                            autoHideDuration={null}
                            ContentProps={{
                              'className': classes.updateSnack,
                              'aria-describedby': 'message-id',
                            }}
                            message={<span id="message-id">
                                      {t('Update available')}
                                      </span>}
                            action={[
                              <Button key="refresh" onClick={() => {
                                  try {
                                    isUpdateAvailable.waiting.postMessage('skipWaiting')
                                  } catch (error)  {}
                                }
                                }>
                                {t('Update')}
                              </Button>,
                            ]}
                          />

                  }
                </DeviceSettingsLoader>
              </Provider>
            </Router>
          </ThemeProvider>
        </div>
      );
    }
  }
}

export default App;
